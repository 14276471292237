import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import React, { Component } from 'react'
import { GridColumn } from '@progress/kendo-react-grid'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { AddNotificationModal } from './modals'
import { Button } from 'reactstrap'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import './_styles.scss'
import { DateTimeCell } from 'components/gridcells/datetime'

type AppNotificationComponentProps = GenericRedux.AllComponentPropsWithModal<any>

class AppNotificationComponent extends Component<AppNotificationComponentProps, any> {
  public static contextType = RasaContext

  constructor(props: AppNotificationComponentProps) {
    super(props)
    this.state = {}
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        error: '',
        isLoading: false,
        selectedCommunity: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return <div className="app-notification-wrapper">
      <div className="header-container">
        <HeaderComponent
          title={'App Notifications'}
          subTitle={'App Notifications'}
          description={[
            'Handle In App Notifications.',
          ]}/>
        <div className="add-new-notification-wrapper">
            <Button className="add-button add-new-notification " onClick={() =>
              this.props.openModal(AddNotificationModal.key, {})
            }>
              <span className="button-text">Add Notification</span>
            </Button>
        </div>
      </div>
      {this.state.isLoading ?
        <Loading size="64"/>
      :
        <div>
          <AddNotificationModal
            data={this.props.modals}
            updateModal={this.props.updateModal}
            openModal={this.props.openModal}
            saveModal={this.props.saveModal}
            closeModal={this.props.closeModal}
            title="Add/Edit Notification"
            createOrUpdate={this.saveNotification} />
          <RasaDataGrid
            entityName="rasaNotification"
            datasetName="rasaNotifications"
            editDefaultState={false}
            sortable={true}
            pageable={false}
            data={[]}
            forceReload={this.state.forceReload}>
          <GridColumn field="title" title="Title" width={100}/>
          <GridColumn cell={this.descriptionCell} title="Description"/>
          <GridColumn cell={DateTimeCell} field="start_date" title="Start Date"/>
          <GridColumn cell={DateTimeCell} field="end_date" title="End Date"/>
          <GridColumn field="status" title="Status"/>
          <GridColumn cell={this.editCell} title=""/>
        </RasaDataGrid>
        </div>
      }
    </div>
  }

  private descriptionCell = (props: EditCellProps) => {
    return <td dangerouslySetInnerHTML={{__html: props.dataItem.description}}></td>
  }

  private editCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props}
    canEdit={true}
    onEdit={this.onEdit}
    confirmOnArchive={true}
    canArchive={true} />
  }

  private onEdit = (props: any) => {
    this.props.openModal(AddNotificationModal.key, props)
  }

  private saveNotification = (payload: any) => {
    this.context.entityMetadata.getEntityObject('rasaNotification').then(
      (entityObject: any ) => {
      entityObject.setRecordId(this.state.communityId, payload.id)
      entityObject.data.title = payload.title
      entityObject.data.description = payload.description
      entityObject.data.start_date = payload.start_date
      entityObject.data.end_date = payload.end_date
      entityObject.data.status = payload.status
      entityObject.save().then(() => {
        this.setState({forceReload: true}, () => this.setState({forceReload: false}))
      })
    })
    return Promise.resolve(null)
  }
}


export const AppNotification = GenericRedux.registerNewComponentWithModals<any>(
  AppNotificationComponent,
  'app_notifications',
  [AddNotificationModal.key]
  )
