import { DropdownOption } from "components/dropdown/component"

export const REDUX_STORE_HOME = 'app_notifications'
export interface Notification {
  id?: string,
  title: string,
  description: string,
  start_date: Date,
  end_date: Date,
  status: string,
  link_url: string,
}

export enum NotificationOption {
  // Important that default also be 0 - so "not specified" is same as "0" as "falsey" values
  // And these should be strings - since that's what the underlying properties are
  PUBLISHED = 'Published',
  DRAFT = 'Draft',
}

export const StatusFilters: DropdownOption[] = [
  {
    description: NotificationOption.PUBLISHED,
    key: NotificationOption.PUBLISHED,
    value: NotificationOption.PUBLISHED,
  },
  {
    description: NotificationOption.DRAFT,
    key: NotificationOption.DRAFT,
    value: NotificationOption.DRAFT,
  },
]