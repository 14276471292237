import { Loading } from 'components/loading'
import { TinymceEditorComponent } from 'components/tinymce-editor/tinymceditor'
import { RasaContext } from 'context'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import { Editor as TinyMCEEditor } from "tinymce";
import * as Constants from './constants'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'

interface State {
  selectedStatus: DropdownOption,
}
type AddNotificationState = Constants.Notification & Modals.ModalComponentState & State

interface AddNotificationProps extends Modals.ModalComponentProps {
  push: any,
  createOrUpdate: (payload: any) => Promise<any>
}

export class AddNotificationModalComponent extends Modals.GenericModalComponent<AddNotificationProps,
AddNotificationState> {
  public static contextType = RasaContext
  public static key: string = 'addNotification'
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Save',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Add Notification',
  }
  private _tinymceInstance: TinyMCEEditor
  private isSaveButtonDisabled: boolean = false
  constructor(props: AddNotificationProps) {
    super(props, AddNotificationModalComponent.key, 'Add Notification')
    const modalData = this.props.data[this.key].data
    this.state = {
      title: modalData.title,
      description: modalData.description,
      start_date: modalData.start_date,
      end_date: modalData.end_date,
      status: modalData.status || Constants.StatusFilters[0].value,
      link_url: modalData.link_url,
      isSaving: false,
      selectedStatus: Constants.StatusFilters[0],
    }
  }

  public componentWillReceiveProps(nextProps) {
    const modalData = nextProps.data[this.key].data
    if (modalData.id && modalData.id !== this.state.id) {
      this.setState({id: modalData.id})
    }
    if (modalData.title && modalData.title !== this.state.title) {
      this.setState({title: modalData.title})
    }
    if (modalData.description && modalData.description !== this.state.description) {
      this.setState({description: modalData.description})
    }
    if (modalData.start_date && modalData.start_date !== this.state.start_date) {
      this.setState({start_date: new Date(modalData.start_date)})
    }
    if (modalData.end_date && modalData.end_date !== this.state.end_date) {
      this.setState({end_date: new Date(modalData.end_date)})
    }
    if (modalData.status && modalData.status !== this.state.status) {
      this.setState({status: modalData.status})
    }
    if (modalData.link_url && modalData.link_url !== this.state.link_url) {
      this.setState({link_url: modalData.link_url})
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="add-notification-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div className='add-notification-form'>
            <div className='input-wrapper'>
              <h4>Title</h4>
              <input
                type="text"
                value={this.state.title}
                placeholder="Enter notification title..."
                onChange={(e) => {
                  e.preventDefault()
                  this.setState({ title: e.target.value })
                }}
              />
            </div>
            <div className='input-wrapper'>
              <h4>Description</h4>
              <TinymceEditorComponent
                html={this.state.description ? this.state.description : ''}
                height='413'
                instance={(editor: TinyMCEEditor) =>  this.tinymceInstance(editor)}
                onChange={(html: string) => this.articleDescription(html) }
                showCharacterCount={true} />
            </div>
            <div className='input-wrapper-three-column'>
              <div className='child-input-wrapper'>
                <h4>Start Date</h4>
                <DatePicker className="start-date-picker"
                  format="yyyy-MM-dd"
                  value={this.state.start_date}
                  onChange={(e) => this.setState({start_date: e.value})} ></DatePicker>
              </div>
              <div className='child-input-wrapper'>
                <h4>End Date</h4>
                <DatePicker className="end-date-picker"
                  format="yyyy-MM-dd"
                  value={this.state.end_date}
                  onChange={(e) => this.setState({end_date: e.value})} ></DatePicker>
              </div>
              <div className='child-input-wrapper'>
                <h4>Status</h4>
                <DropdownComponent data={Constants.StatusFilters} selected={this.state.selectedStatus.key}
                  onChange={this.statusChanged}/>
              </div>
            </div>
          </div>
        }
        <div>
        { this.isSaveButtonDisabled
        ? <div className="invalid-source-name-container">You already have a section with this name</div>
        : null}
        </div>
      </div>
    )
  }

  protected statusChanged = (e: OnChangeEvent) => {
    this.setState({
      selectedStatus: e.selected,
      status: e.selected.value,
    })
  }

  protected tinymceInstance = (instance: TinyMCEEditor) => {
    this._tinymceInstance = instance
  }

  protected articleDescription = (description: string) => {
    if(this._tinymceInstance.isDirty()) {
      this.setState({
        description
      })
    }
  }

  protected close = (data: any) => {
    return this.props.closeModal(AddNotificationModal.key)
  }

  protected saveDisabled(data: any): boolean {
    return this.state.title === '' || this.state.description === '' || this.state.start_date === null
  }

  protected doSave(data: any) {
    return this.props.createOrUpdate({
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      status: this.state.status,
      link_url: this.state.link_url
    }).then(() => {
      this.setState({
        isSaving: false,
        id: '',
        title: '',
        description: '',
        start_date: null,
        end_date: null,
        status: '',
        link_url: ''
      })
    });
  }
}

export const AddNotificationModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddNotificationModalComponent)
